import React from "react"

const NotFoundPage = () => (
  <main>
    <h1>404: Page Not Found</h1>
  </main>
)

export const Head = () => <title>404: Page not found</title>

export default NotFoundPage
